<template>
  <van-nav-bar
      :title="title"
      @click-left="onClickLeft"
  >
    <template #left>
<!--      <van-icon name="arrow-left" color="#000000" size="26" />-->
      <div class="left"></div>
    </template>
  </van-nav-bar>
</template>

<script>
import {NavBar} from "vant";

export default {
  name: "NavBar",
  components:{
    [NavBar.name]:NavBar,
  },
  methods:{
    onClickLeft() {
      this.$router.go(-1)
    }
  },
  data(){
    return{

    }
  },
  props:{
    title:String
  }
}
</script>

<style scoped>
>>>.van-nav-bar__content {
  height: 88px;
}
>>>.van-nav-bar__title {
  width: 750px;
  height: 48px;
  font-size: 34px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #000000;
  line-height: 40px;
}
.left {
  width: 18px;
  height: 18px;
  border-top: 2.5px solid rgba(0, 0, 0, 0.71);
  border-left: 2.5px solid rgba(0, 0, 0, 0.71);
  transform: rotate(-45deg);
  margin-left: 13.5px;
}

</style>